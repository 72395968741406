import React from "react";
import "./custom.css";
import Home from "./pages/HomePage";
import ContactUs from "./pages/ContactUsPage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import CleaningCheckListPage from "./pages/CleaningChecklistPage";
import TermsAndConditionPage from "./pages/TermsAndConditionPage";
import BookingPage from "./pages/BookingPage";
import Nav from "./components/Navbar/Nav";
import NotFoundPage from "./pages/404NotFoundPage";

export default function App() {
  return (
    <>
      <Router>
        <div className="mobnav-shadow desk-shadow">
          <BookingPageNavHide />
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/cleaning_checklist"
            element={<CleaningCheckListPage />}
          />
          <Route path="/contact" element={<ContactUs />} />
          <Route
            path="/terms-and-condition"
            element={<TermsAndConditionPage />}
          />
          <Route path="/booking-page" element={<BookingPage />} />
          <Route path="/not-found" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </>
  );
}

export function BookingPageNavHide() {
  const location = useLocation();
  const hideNavBar = location.pathname === "/booking-page";
  return <>{!hideNavBar && <Nav />}</>;
}
