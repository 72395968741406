"use client";
import HeroBannerContent from "./HeroBannerContent";
export default function HeroSection({
  heroBanner,
  homeContentHeading,
  deskContentPara,
  customleft,
  customtop,
  customfontSize,
  customleading,
  mobContentPara,
}) {
  return (
    <>
      <div
        className={`md:absolute bg-white bg-opacity-80 md:w-[653px] ${customtop} ${customleft} rounded-3xl md:p-6 flex flex-col gap-6`}
      >
        <HeroBannerContent
          HeroContentHeading={homeContentHeading}
          deskContentPara={deskContentPara}
          customfontSize={customfontSize}
          customleading={customleading}
          mobContentPara={mobContentPara}
        />
      </div>
      <div className="p-4 sm:flex md:p-0 sm:h-[242px]">
        <img
          src={heroBanner}
          alt="herobanner"
          className="rounded-lg md:rounded-none"
        />
      </div>
    </>
  );
}
