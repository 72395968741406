import React from "react";

const BookingCounter = ({
  setFormData,
  counterKey,
  counterHeading,
  error,
  fieldValue = 0,
}) => {
  const calculateTotal = (stateFormData, newValue) => {
    var numLivingAreas =
      counterKey === "counter1" ? newValue : stateFormData.step1Data.counter1;
    var numWashRooms =
      counterKey === "counter2" ? newValue : stateFormData.step1Data.counter2;
    var numBedRooms =
      counterKey === "counter3" ? newValue : stateFormData.step1Data.counter3;

    return numBedRooms
      ? numBedRooms > 0 && numBedRooms < 3
        ? 120
        : numBedRooms > 2 && numBedRooms < 5
        ? 140
        : 200
      : numWashRooms * 30;
  };

  const handleCounterChange = (counterKey, newValue) => {
    setFormData((prev) => ({
      ...prev,
      step1Data: {
        ...prev.step1Data,
        [counterKey]: newValue,
        totalPrice: calculateTotal(prev, newValue),
      },
    }));
  };

  // Handle increment
  const handleIncrement = () => {
    const newValue = fieldValue + 1;
    handleCounterChange(counterKey, newValue);
  };

  // Handle decrement
  const handleDecrement = () => {
    const newValue = Math.max(0, fieldValue - 1); // Ensure value is non-negative
    handleCounterChange(counterKey, newValue);
  };

  return (
    <>
      <div className="flex flex-col gap-1 ">
        <p className="inter-medium text-sm line-height-22 custom-gray08">
          {counterHeading}
        </p>

        <div className="flex gap-3 items-center contact-form ">
          <input
            value={fieldValue}
            onChange={handleCounterChange}
            min="0"
            type="number"
            className="form-field remove-arrow max-w-[107px] h-12 rounded-md px-3 py-2 flex gap-1  custom-blue02 outline-none"
          />

          <div className="flex flex-row items-center justify-center gap-4">
            <button
              className="flex justify-center items-center custom-blue-text w-4 h-4 text-2xl custom-blue01 rounded-full border-none"
              onClick={handleDecrement}
            >
              -
            </button>
            <button
              className="flex justify-center items-center custom-blue-text w-4 h-4 text-2xl custom-blue01 rounded-full border-none"
              onClick={handleIncrement}
            >
              +
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingCounter;
