import React from "react";
import TermsAndCondition from "../components/TermsAndConditionComp/TermsAndCondition";
const TermsAndConditionPage = () => {
  return (
    <>
      <div className="relative">
        <TermsAndCondition />
      </div>
    </>
  );
};

export default TermsAndConditionPage;
