import React from "react";
import Button from "../Button";
import LogoText from "../../assets/bookinglogo.png";
import { Link } from "react-router-dom";
const BookingNav = ({ totalBookingPrice, currentStep }) => {
  return (
    <>
      <div className="w-full md:h-[100px] sm:p-4 md:pt-8 px-8">
        <div className="flex flex-row md:w-[1376px] justify-between items-center">
          <Link to="/">
            <img
              src={LogoText}
              alt="logoText"
              className="w-[156px] object-cover"
            />
          </Link>
          <div className="h-12 flex items-center gap-4">
            <span className="sm:hidden custom-gray10 inter-normal text-xs leading-[15px]">
              Need Help?
            </span>
            <span
              className={` ${
                currentStep === 6 && "sm:hidden"
              } md:hidden custom-gray08 inter-medium text-xl leading-[18px]`}
            >
              ${currentStep === 1 ? 0 : totalBookingPrice.totalPrice}
            </span>
            <Button
              className=" sm:hidden md:min-w-[200px] h-12 text-sm md:rounded-md rounded-none inter-medium text-center custom-gray10 custom-border md:border-[#BFBFBF]"
              callDetails={"Call (0474769147)"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingNav;
