import React from "react";
const Button = ({ className, callDetails, onclick }) => {
  return (
    <button onClick={onclick} className={`inter-medium ${className}`}>
      {callDetails}
    </button>
  );
};

export default Button;
