import React from "react";
import MasterCardImg from "../../assets/masterCreditcard.png";
const BadgeButton = ({ formData, onclick }) => {
  return (
    <>
      <button
        onClick={onclick}
        className={`form-field popular-button flex flex-row gap-4 justify-start items-center  w-full h-[102px] rounded-xl ${
          formData.paymentMethod === "Credit Card" ? "selected"  : ""
        } px-4 py-2 mb-2`}
      >
        <div className="badge inter-600 text-[8px] text-center leading-[10px] text-white">
          MOST POPULAR
        </div>
        <div className="flex flex-row gap-4 justify-start items-center">
          <img src={MasterCardImg} alt="mastercard" />

          <div className="text-start imnter-medium text-base line-height-22">
            Credit Card
            <p className="inter-normal text-sm line-height-22 custom-gray06">
              *Includes service fee
            </p>
          </div>
        </div>
      </button>
    </>
  );
};

export default BadgeButton;
