import React from "react";

const BookingNextPrevBtns = ({ currentStep, handlePrevious, handleNext }) => {
  return (
    <>
      <div className="flex flex-row p-4 gap-4">
        {currentStep < 6 && (
          <button
            className="custom-gray08 rounded-md w-1/2 border custom-gray04 py-[9px]"
            type="button"
            onClick={handlePrevious}
            disabled={currentStep === 1}
          >
            Back
          </button>
        )}
        {currentStep <=4 && (
          <button
            className="text-white rounded-md w-1/2 border custom-blue-bg py-[9px]"
            type="button"
            onClick={handleNext}
          >
            Next
          </button>
        )}
         {currentStep === 5 && (
          <button
            className="text-white rounded-md w-1/2 border custom-blue-bg py-[9px]"
            type="button"
            onClick={handleNext}
          >
            Booking complete
          </button>
        )}
      </div>
    </>
  );
};

export default BookingNextPrevBtns;
