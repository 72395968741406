import React from "react";
import userIcon from "../../assets/user.png";
import phoneIcon from "../../assets/phone.png";
import envelopIcon from "../../assets/envelope.png";
import "react-toastify/dist/ReactToastify.css";
import locationIcon from "../../assets/location-pin.png";
import BookingStepsHeading from "./BookingStepsHeading";
export default function BookingContactUs({
  formData,
  setFormData,
  stepsnum,
}) {
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      step3Data: {
        ...prev.step3Data,
        [name]: value,
      },
    }));
  };

  return (
    <>
      <div className="w-full p-4 flex flex-col sm:gap-6 gap-10">
        <div className="flex flex-col gap-1">
          <BookingStepsHeading
            stepsHeading="Now, please share your contact details:"
            stepsnum={stepsnum}
          />
        </div>
        <form className="bookingcontactform contact-form">
          <div className="sm:gap-2.5 px-0 gap-0">
            <div className="rounded-2xl flex flex-col gap-4">
              <div className="flex flex-col justify-center gap-6">
                <div className="contact-inputBox-border form-field rounded-md flex flex-row justify-start items-center gap-1 h-12  px-3 py-2">
                  <div className="flex flex-row gap-2 justify-center items-center w-full">
                    <img
                      src={userIcon}
                      alt="usericon"
                      className="object-none h-6"
                    />
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Name"
                      className="inter-normal custom-text-gray06  block w-full text-sm rounded-md focus:outline-none line-height-22"
                    />
                  </div>
                </div>
                <div className="contact-inputBox-border form-field rounded-md flex flex-row justify-start items-center gap-1 h-12  px-3 py-2">
                  <div className="flex flex-row gap-2 justify-center items-center w-full">
                    <span className="inter-normal text-[#ACACAC] text-lg">
                      @
                    </span>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Email"
                      className="inter-normal custom-text-gray06  block w-full text-sm rounded-md focus:outline-none line-height-22"
                    />
                  </div>
                </div>
                <div className="contact-inputBox-border form-field rounded-md flex flex-row justify-start items-center gap-1 h-12  px-3 py-2">
                  <div className="flex flex-row gap-2 justify-center items-center w-full">
                    <img
                      src={phoneIcon}
                      alt="phoneicon"
                      className="object-none h-6"
                    />
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      value={formData.phone}
                      onChange={handleInputChange}
                      placeholder="Phone Number"
                      pattern="\d{10}"
                      maxLength={10}
                      className="inter-normal custom-text-gray06 block w-full focus:outline-none text-sm rounded-md line-height-22"
                    />
                  </div>
                </div>
                <div className="contact-inputBox-border form-field rounded-md flex flex-row justify-start items-center gap-1 h-12  px-3 py-2">
                  <div className="flex flex-row gap-2 justify-center items-center w-full">
                    <img
                      src={locationIcon}
                      alt="envolopeicon"
                      className="object-none h-6"
                    />
                    <input
                      id="adress"
                      name="address"
                      type="text"
                      value={formData.address}
                      onChange={handleInputChange}
                      placeholder="Address"
                      className="inter-normal custom-text-gray06 block w-full text-sm rounded-md focus:outline-none line-height-22"
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <div className="contact-inputBox-border form-field rounded-md flex flex-row justify-start items-center gap-1 h-12  px-3 py-2">
                    <input
                      id="suburb"
                      name="suburb"
                      type="text"
                      value={formData.suburb}
                      onChange={handleInputChange}
                      placeholder="Suburb"
                      className="inter-normal custom-text-gray06 block w-full text-sm rounded-md focus:outline-none line-height-22"
                    />
                  </div>
                  <div className="contact-inputBox-border form-field rounded-md flex flex-row justify-start items-center gap-1 h-12  px-3 py-2">
                    <input
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      placeholder="State"
                      className="inter-normal remove-arrow focus:outline-none"
                    />
                  </div>
                </div>
                <div className="contact-inputBox-border form-field rounded-md flex flex-row justify-start items-center gap-1 h-12  px-3 py-2">
                  <div className="flex flex-row gap-2 justify-center items-center w-full">
                    <img
                      src={envelopIcon}
                      alt="envolopeicon"
                      className="object-none h-6"
                    />
                    <input
                      id="postcode"
                      name="postcode"
                      type="text"
                      pattern="\d*"
                      maxLength={4}
                      value={formData.postcode}
                      onChange={handleInputChange}
                      placeholder="Postcode"
                      className="inter-normal custom-text-gray06 block w-full text-sm rounded-md focus:outline-none line-height-22"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

//<div
// className={`${
//   step3Error.name
//     ? "border border-red-500"
//     : "contact-inputBox-border"
// } form-field rounded-md flex flex-row justify-start items-center gap-1 h-12 px-3 py-2`}
// >
