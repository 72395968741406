import React from "react";
import ConnectWithUsComp from "../HomeComp/HeroBanner/ConnectWithUsComp";
import { Link } from "react-router-dom";
const TermsAndCondition = () => {
  return (
    <>
      <div className="mt-4 md:mt-[49px] flex flex-col gap-6 sm:p-4 md:w-[1040px] mx-auto">
        <h1 className="md:tracking-wider sm:text-left text-center inter-medium custom-gray10 sm:text-[28px] text-[64px] sm:leading-9 leading-[77px]">
          Terms and Conditions
        </h1>
        <div className="flex flex-col gap-6">
          <div className=" flex flex-col gap-6">
            <span className="custom-gray08 inter-700 text-sm line-height-22">
              WHO ARE WE?
            </span>
            <p className="custom-gray08 inter-normal text-sm line-height-22">
              We are HomeGlitz, <Link to="www.homeglitz.com.au"  className="custom-blue-text">www.homeglitz.com.au</Link>,  ABN 67 837 543 479,
              offering domestic cleaning services in Western Australia. We
              provide only domestic cleaning services and do not offer our
              services to commercial or government agencies. Any person or
              business offering services outside of domestic cleaning does not
              belong to us, and we will not be held responsible for their acts
              or damages caused.
            </p>
            <p className="custom-gray08 inter-normal text-sm line-height-22">
              Please read these terms and conditions before engaging with us. By
              engaging with us, you agree to abide by these terms and conditions
              in their entirety.
            </p>
            <p>---</p>
          </div>
          <div className="flex flex-col gap-6">
            <span className="custom-gray08 inter-700 text-sm line-height-22">
              DOMESTIC CLEANING SERVICES
            </span>
            <p className="custom-gray08 inter-normal text-sm line-height-22">
              Upon engagement, it is agreed that our contract will cover only
              domestic cleaning services. Any work outside this scope will not
              be performed by us. HomeGlitz will offer its services at the time
              and address provided by the customer and will complete the work
              for that location only, subject to the agreed sum of money. Extra
              charges will apply for any additional work at different locations.
            </p>
            <p className="custom-gray08 inter-normal text-sm line-height-22">
              HomeGlitz will provide workers/cleaners, cleaning materials, and
              equipment. We strive to provide our services to the best of our
              abilities and in a professional manner.
            </p>
            <p>---</p>
          </div>
          <div className="flex flex-col gap-6">
            <span className="custom-gray08 inter-700 text-sm line-height-22">
              RESPONSIBILITY OF THE CUSTOMERS
            </span>
            <p className="custom-gray08 inter-normal text-sm line-height-22">
              - The customer is responsible for providing the exact location and
              address of the premises to be cleaned.
              <br /> - The customer must provide free access to the premises and
              a safe working environment free from hazards.
              <br /> - The customer must provide access to water and electricity
              unless otherwise agreed, in which case extra charges may apply.
              <br /> - If the customer insists on using their cleaning
              materials/equipment, these must be in working order and not
              hazardous. HomeGlitz will not be responsible for the results of
              using such materials/equipment. <br />- The customer must move
              heavy/bulky items (e.g., bookshelves, stands, sofas) outside the
              premises.
              <br /> - The customer must carefully move fragile, breakable, and
              precious items (e.g., showpieces, drawings, sculptures, paintings)
              before cleaning begins. HomeGlitz will not be responsible for any
              damage or loss to such items during cleaning
            </p>
            <p>---</p>
          </div>
          <div className="flex flex-col gap-6">
            <span className="custom-gray08 inter-700 text-sm line-height-22">
              SAFE AND SOUND WORKING ENVIRONMENT
            </span>
            <p className="custom-gray08 inter-normal text-sm line-height-22">
              - The customer agrees to allow HomeGlitz or its authorized agents
              to conduct a safety inspection of the premises to be cleaned. Any
              undisclosed hazards that cause injury to a worker/cleaner will
              result in the customer being liable for damages.
              <br /> - Workers/cleaners may refuse to use customer-provided
              cleaning materials if they pose a health risk. In such cases, they
              will not be forced to use the materials and will not be liable for
              any damages.
              <br /> - For long-term engagements, the customer agrees not to
              engage any other cleaning services without prior notice to
              HomeGlitz. Failure to do so will result in liability for damages
              as agreed upon.
            </p>
            <p>---</p>
          </div>
          <div className=" flex flex-col gap-6">
            <span className="custom-gray08 inter-700 text-sm line-height-22">
              PRICE CALCULATION
            </span>
            <p className="custom-gray08 inter-normal text-sm line-height-22">
              - Online Payment by Credit Card: The customer must tender payment
              at the time of booking on the HomeGlitz website. Delays in payment
              will result in liability for damages and debt collection charges.{" "}
              <br />- HomeGlitz will provide a payment receipt.
            </p>
            <p>---</p>
          </div>
          <div className=" flex flex-col gap-6">
            <span className="custom-gray08 inter-700 text-sm line-height-22">
              MODE OF PAYMENT
            </span>
            <p className="custom-gray08 inter-normal text-sm line-height-22">
              - Online Payment by Credit Card: The customer must tender payment
              at the time of booking on the HomeGlitz website. Delays in payment
              will result in liability for damages and debt collection charges.
              <br /> - HomeGlitz will provide a payment receipt
            </p>
            <p>---</p>
          </div>
          <div className="flex flex-col gap-6">
            <span className="custom-gray08 inter-700 text-sm line-height-22">
              TAXES AND INVOICES
            </span>
            <p className="custom-gray08 inter-normal text-sm line-height-22">
              - Payments are calculated after the deduction of applicable
              taxes/GST. <br />- Invoices will be issued to HomeGlitz after the
              deduction of applicable taxes borne by the customer.
            </p>
            <p>---</p>
          </div>
          <div className=" flex flex-col gap-6">
            <span className="custom-gray08 inter-700 text-sm line-height-22">
              COMPLAINTS REGARDING WORK AND COSTS OF SUITS
            </span>
            <p className="custom-gray08 inter-normal text-sm line-height-22">
              - HomeGlitz strives for 100% client satisfaction. Customers must
              inform HomeGlitz of any complaints within 1 business day. If not
              satisfied, reimbursement may be provided subject to certain
              conditions, such as no renovation, repair, shelving, or
              construction work performed after cleaning, and the premises
              remained vacant after cleaning. <br /> - Costs of suits will be
              borne by the party at fault.
            </p>
            <p>---</p>
          </div>
          <div className="flex flex-col gap-6">
            <span className="custom-gray08 inter-700 text-sm line-height-22">
              APPLICABLE LAWS, JURISDICTION OF COURT, AND WARRANTIES
            </span>
            <p className="custom-gray08 inter-normal text-sm line-height-22">
              - HomeGlitz will not be responsible for defects or losses not
              mentioned at the time of entry, non-fulfillment of service due to
              customer negligence, or damages from natural wear and tear. <br />
              - Customers must report any loss/theft/damage within 24 hours
              after work completion. HomeGlitz is not liable for jewelry, gold,
              antiques, or other valuable items not moved to safe custody.{" "}
              <br />
              - The customer is responsible for ensuring safe outflow of
              cleaning water/chemicals. HomeGlitz is not liable for damages from
              blocked outflow. <br />
              - Western Australia courts have jurisdiction for any contract
              disputes. <br />- Terms and conditions are governed by applicable
              laws in Western Australia.
            </p>
            <p>---</p>
          </div>
          <div className=" flex flex-col gap-6">
            <span className="custom-gray08 inter-700 text-sm line-height-22">
              LAWS GOVERNING CLEANING SERVICES IN AUSTRALIA
            </span>
            <p className="custom-gray08 inter-normal text-sm line-height-22">
              - Modernised Cleaning Services Award 2010 <br /> - The Fair Work
              Act 2009 <br />- Commonwealth Cleaning Services Guidelines
              (current as of 1 July 2012)
            </p>
            <p>---</p>

            <div className="md:w-full flex flex-col gap-5">
              <span className="custom-gray08 inter-700 text-sm line-height-22">
                INDEMNIFICATION AND FEES
              </span>
              <p className="custom-gray08 inter-normal text-sm line-height-22">
                - Customers will indemnify HomeGlitz for loss of time/work due
                to non-access or willful neglect. <br />- In case of contract
                cancellation by the customer without prior notice, the customer
                will pay 50% of the contract price.
              </p>
              <p>---</p>
            </div>
            <div className="md:w-full flex flex-col gap-5">
              <span className="custom-gray08 inter-700 text-sm line-height-22">
                TERMINATION OF CONTRACT OR CHANGES TO IT
              </span>
              <p className="custom-gray08 inter-normal text-sm line-height-22">
                - Either party may terminate the contract with 24 hours’ notice.
                If terminated by the customer without notice, 50% of the
                contract price will be waived. If terminated by HomeGlitz,
                contract money will be returned. <br />- Changes to the contract
                require mutual consent. Unilateral changes will not be
                enforceable.
              </p>
              <p>---</p>
            </div>
            <div className="md:w-full flex flex-col gap-5">
              <span className="custom-gray08 inter-700 text-sm line-height-22">
                PRIVACY POLICY
              </span>
              <p className="custom-gray08 inter-normal text-sm line-height-22">
                - HomeGlitz will keep all customer information (emails, phone
                numbers, debit card numbers, and addresses) secure and private.{" "}
                <br />
                - Customers can amend their particulars.
                <br />- Information will be destroyed every six months.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block w-[100%] h-[100px] py-[10px] md:py-[26px]">
        <ConnectWithUsComp textColor="text-black" />
      </div>
    </>
  );
};

export default TermsAndCondition;
