import React, { useState } from "react";
import ConnectWithUsComp from "../HomeComp/HeroBanner/ConnectWithUsComp";
import Button from "../Button";
import userIcon from "../../assets/user.png";
import phoneIcon from "../../assets/phone.png";
import envelopIcon from "../../assets/envelope.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "./Toast";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    comment: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    phone: "",
    email: "",
    comment: "",
    formValid: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const contactFormValidate = async () => {
    let formErrorsObj = {
      name: "",
      phone: "",
      email: "",
      comment: "",
      formValid: true,
    };

    if (!formData.name) {
      formErrorsObj.name = "Name is required";
      formErrorsObj.formValid = false;
    }

    if (!formData.phone) {
      formErrorsObj.phone = "Phone number is required";
      formErrorsObj.formValid = false;
    }

    if (!formData.email) {
      formErrorsObj.email = "Email is required";
      formErrorsObj.formValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrorsObj.email = "Email is invalid";
      formErrorsObj.formValid = false;
    }

    if (!formData.comment) {
      formErrorsObj.comment = "Comment is required";
      formErrorsObj.formValid = false;
    }
    setFormErrors(formErrorsObj);

    if (formErrorsObj.formValid === true) {
      try {
        const response = await fetch(
          "https://api.homeglitz.com.au/contact",
          {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );
        if (response?.ok) {
          toast.success(
            "Thank you for contactig us! Kindly check your inbox for further steps."
          );
        }
      } catch (error) {
        toast.error("Something went wrong! Please try again later.");
      }
    } else {
      toast.error(<Toast errors={formErrorsObj} />);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (contactFormValidate()) {
      setFormData({
        ...formData,
      });
    }
  };

  return (
    <>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="md:w-[654px] mt-[5px] mx-auto md:px-8 flex flex-col gap-6">
          <div className="w-full sm:p-4 gap-4 md:gap-6 flex flex-col sm:text-start text-center">
            <h1 className="inter-medium custom-gray10 sm:text-[28px] text-[64px] sm:leading-9 leading-[78px]">
              Contact Us
            </h1>
            <p className="inter-normal custom-gray08 text-sm line-height-22">
              Our team is here to help and will respond to your inquiries as
              soon as possible. Thank <br /> you for choosing HomeGlitz!
            </p>
            <p className="inter-normal custom-gray08 text-sm line-height-22 mt-0">
              Phone: 0474 769 147 <br /> Email: admin@homeglitz.com.au
            </p>
          </div>
          <div className=" sm:px-4 sm:gap-2.5 px-0 gap-0">
            <div className="rounded-2xl flex flex-col gap-4 p-4 custom-border">
              <div className="flex flex-col justify-center gap-6">
                <div
                  className={`${
                    formErrors.name
                      ? "border border-red-500"
                      : "contact-inputBox-border"
                  } form-field rounded-md flex flex-row justify-start items-center gap-1 h-12  px-3 py-2 `}
                >
                  <div className="flex flex-row gap-2 justify-center items-center w-full">
                    <img
                      src={userIcon}
                      alt="usericon"
                      className="object-none h-6"
                    />
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Name"
                      className="inter-normal custom-text-gray06  block w-full text-sm rounded-md focus:outline-none line-height-22"
                    />
                  </div>
                </div>
                <div
                  className={`${
                    formErrors.phone
                      ? "border border-red-500"
                      : "contact-inputBox-border"
                  } form-field  rounded-md flex flex-row justify-start items-center gap-1 h-12  px-3 py-2`}
                >
                  <div className="flex flex-row gap-2 justify-center items-center w-full">
                    <img
                      src={phoneIcon}
                      alt="phoneicon"
                      className="object-none h-6"
                    />
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Phone Number"
                      pattern="\d{10}"
                      maxLength={10}
                      className="inter-normal custom-text-gray06 block w-full focus:outline-none text-sm rounded-md line-height-22"
                    />
                  </div>
                </div>
                <div
                  className={`${
                    formErrors.email
                      ? "border border-red-500"
                      : "contact-inputBox-border"
                  } form-field rounded-md flex flex-row justify-start items-center gap-1 h-12 px-3 py-2 `}
                >
                  <div className="flex flex-row gap-2 justify-center items-center w-full">
                    <img
                      src={envelopIcon}
                      alt="envolopeicon"
                      className="object-none h-6"
                    />
                    <input
                      id="email"
                      name="email"
                      type="text"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                      className="inter-normal custom-text-gray06 block w-full text-sm rounded-md focus:outline-none line-height-22"
                    />
                  </div>
                </div>
                <textarea
                  id="comment"
                  name="comment"
                  type=""
                  value={formData.comment}
                  onChange={handleChange}
                  className={`${
                    formErrors.email
                      ? "border border-red-500"
                      : "contact-inputBox-border "
                  }  resize-none rounded-md form-field focus:outline-none w-full min-h-20 px-3 py-2 text-sm line-height-22 inter-normal custom-text-gray06`}
                  placeholder="Comment"
                />
              </div>

              <Button
                type="submit"
                callDetails={"Send email to HomeGlitz"}
                className="w-full btn-bg custom-blue-border text-white inter-medium text-sm line-height-22 text-center py-2 rounded-md"
              />
            </div>
          </div>
          <ToastContainer />
        </div>
      </form>
      <div className="hidden md:block w-[100%] h-[100px] py-[10px] md:py-[26px]">
        <ConnectWithUsComp textColor="text-black" />
      </div>
    </>
  );
}
