import React from "react";

const Enquiry = ({ enquiries, timing }) => {
  return (
    <div className="hidden md:block  text-end custom-gray08">
      <p className="inter-normal text-xs leading-[15px]">{enquiries}</p>
      <p className="inter-medium text-xs leading-[15px] enquiry-para">{timing}</p>
    </div>
  );
};

export default Enquiry;
