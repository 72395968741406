import React from "react";
import HeroSection from "../components/HeroSection";
import cleaningCheckList from "./../assets/person-taking-care-banner.jpg";
import CleaningServices from "../components/CleaningComp/CleaningServices";
import ConnectWithUsComp from "../components/HomeComp/HeroBanner/ConnectWithUsComp";
const CleaningCheckListPage = () => {
  return (
    <>
      <div className="md:relative">
        <HeroSection
          cusHidden="sm:hidden md:block"
          customfontSize="md:text-[46px]"
          customtop="md:top-[123px]"
          customleft="md:left-[67px]"
          customleading="md:leading-[56px] leading-9"
          heroBanner={cleaningCheckList}
          homeContentHeading={
            "Standard Inclusions for Basic Recurring Home Cleaning Service"
          }
          deskContentPara={
            "At HomeGlitz, we ensure your home is meticulously cleaned using top-of-the-range domestic cleaning products and our own professional equipment."
          }
          mobContentPara={
            "We take pride in providing a thorough clean with each visit, ensuring your home remains fresh and spotless."
          }
        />
        <CleaningServices
          cleaningServices={
            "Our basic recurring home cleaning service includes:"}
          cleaningServicePara={
            "We take pride in providing a thorough clean with each visit, ensuring your home remains fresh and spotless."}
        />
        <div className="hidden md:block w-[100%] h-[100px] py-[10px] md:py-[26px]">
          <ConnectWithUsComp textColor="text-black" />
        </div>
      </div>
    </>
  );
};

export default CleaningCheckListPage;
