import React from "react";

const Toast = ({ errors }) => {
  return (
    <>
      <ul>
        <li>{errors.name}</li>
        <li>{errors.phone}</li>
        <li>{errors.email}</li>
        <li>{errors.comment}</li>
      </ul>
    </>
  );
};

export default Toast;
