import React from "react";

const BookingSlider = ({ currentStep }) => {
  const steps = [1, 2, 3, 4];
  return (
    <div className=" flex justify-between gap-1">
      {steps.map((step) => (
        <div
          key={step}
          className={`slider-step ${step <= currentStep ? "completed" : ""}`}
        >
          <input type="checkbox" id="step2" className="sr-only" />
        </div>
      ))}
    </div>
  );
};

export default BookingSlider;
