import React from "react";
import FbIcon from "../../../assets/fb.png";
import WtspIcon from "../../../assets/wtsp.png";
import InstaIcon from "../../../assets/insta.png";
const ConnectWithUsComp = ({ textColor }) => {
  return (
    <>
      <div className="flex absolute right-0 gap-4 rounded-s-lg connectwithus bg-white bg-opacity-20 px-8 py-3">
        <p
          className={`inter-medium text-sm text-center  ${textColor} line-height-22 relative`}
        >
          Connect with us
        </p>
        <div className="flex gap-4 h-6 items-center opacity-100 z-40 blue">
          <div className="w-6 h-6 custom-blue-bg bg-opacity-100  rounded-md relative opacity-100">
            <img
              className="w-4 h-4 absolute top-1 left-1"
              src={FbIcon}
              alt="fbicon"
            />
          </div>
          <div className="w-6 h-6 custom-blue-bg rounded-md relative">
            <img
              className="w-4 h-4 absolute top-1 left-1"
              src={WtspIcon}
              alt="wtspicon"
            />
          </div>
          <div className="w-6 h-6 custom-blue-bg rounded-md relative">
            <img
              className="md:block  w-4 h-4 absolute top-1 left-1"
              src={InstaIcon}
              alt="fbicon"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectWithUsComp;
