import React from "react";

const Step4Error = ({ errors }) => {

  return (
    <>
      <ul>
        <li>{errors.cardNumber}</li>
        <li>{errors.expiryDate}</li>
        <li>{errors.cvv}</li>
        {/* <li>{errors.termsandcondiotions}</li>
        <li>{errors.understandcondiotions}</li> */}
      </ul>
    </>
  );
};

export default Step4Error;
