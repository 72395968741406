import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import calender from "../../assets/calendar-empty-alt.png";

const weekdayOrder = ["S", "M", "T", "W", "T", "F", "S"];

const CalendarInput = ({ setFormData, formData }) => {
  const [date, setDate] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  
  useEffect(() => {
    if (formData.step2Data.date) {
      setDate(formData.step2Data.date);
    }
  }, [formData.step2Data.date]);

  const handleDateChange = (newDate) => {
    setDate(newDate);
    setFormData((prev) => ({
      ...prev,
      step2Data: { ...prev.step2Data, date: newDate },
    }));
    setShowCalendar(false);
  };


  const formatShortWeekday = (locale, date) => {
    const weekDays = weekdayOrder;
    const dayIndex = date.getDay();
    const shiftedDayIndex = (dayIndex + 6) % 7; // Shift to start from Monday
    return weekDays[shiftedDayIndex];
  };

  return (
    <div className="calendar-wrapper flex flex-col gap-1 h-12 px-3 py-2  items-center justify-center">
      <div className="flex w-full focus:outline-none flex-row gap-2 items-center justify-center ">
        <img src={calender} alt="calender-icon" />
        <input
          type="text"
          value={
            formData.step2Data.date
              ? formData.step2Data.date.toDateString()
              : "When would you like the clean to start?"
          }
          onClick={() => setShowCalendar(!showCalendar)}
          readOnly
          placeholder="When would you like the clean to start?"
          className="inter-normal text focus:outline-none w-full custom-gray06 line-height-22"
        />
      </div>
      {showCalendar && (
        <div className="calendar-container inter-normal">
          <Calendar
            onChange={(newDate) => {
              setDate(newDate);
              setShowCalendar(false);
              handleDateChange(newDate);
            }}
            value={date || undefined}
            formatShortWeekday={formatShortWeekday}
          />
        </div>
      )}
    </div>
  );
};

export default CalendarInput;
