import React from "react";
import HeroSection from "../components/HeroSection";
import HeroBanner from "./../assets/herobanner.jpg";

import ConnectWithUsComp from "../components/HomeComp/HeroBanner/ConnectWithUsComp";
const Home = () => {
  return (
    <>
      <div className="md:relative sm:flex sm:flex-col sm:gap-4">
        <HeroSection
          customfontSize="md:text-[56px]"
          customtop="md:top-[141px]"
          customleft="md:left-8"
          customleading="md:leading-[72px]"
          heroBanner={HeroBanner}
          homeContentHeading={"Your LOCAL Home Cleaner!"}
          deskContentPara={
            "HomeGlitz Domestic Cleaners Co. is your trusted partner for premium cleaning services across both metropolitan and regional Western Australia. We specialize in recurring domestic cleans, vacate cleans, Airbnb cleaning and office cleans, providing meticulous attention to detail to ensure every space is spotless and welcoming. Fully insured and with all staff police cleared, we also cater to self-managed NDIS clients, offering tailored services that guarantee a safe, hygienic and immaculate environment. At HomeGlitz, we prioritize reliability and excellence, delivering a consistently high standard of cleaning you can depend on."
          }
          mobContentPara={
            "HomeGlitz Domestic Cleaners Co. is your trusted partner for premium cleaning services across both metropolitan and regional Western Australia. We specialize in recurring domestic cleans, vacate cleans, Airbnb cleaning and office cleans, providing meticulous attention to detail to ensure every space is spotless and welcoming. Fully insured and with all staff police cleared, we also cater to self-managed NDIS clients, offering tailored services that guarantee a safe, hygienic and immaculate environment. At HomeGlitz, we prioritize reliability and excellence, delivering a consistently high standard of cleaning you can depend on."
          }
        />
        <div
          className={`hidden md:block w-[100%] md:absolute h-[100px] md:bottom-0 justify-end py-[10px]  md:py-[26px]`}
        >
          <ConnectWithUsComp textColor="text-white" />
        </div>
      </div>
    </>
  );
};

export default Home;
