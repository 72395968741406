import React from "react";

const BookingSummary = ({ totalBookingPrice, contactDetails, currentStep }) => {
  return (
    <div className="sm:hidden flex flex-col gap-2.5 py-[42px] w-[315px] h-[407px]">
      <div className="flex flex-col gap-[37px] rounded-lg summary-border h-auto ">
        <h2 className="w-fill inter-medium text-base leading-[19px] custom-gray09 p-4 flex gap-2.5 custom-border-bottom">
          Booking Summary
        </h2>
        <div className="w-full flex flex-col gap-4 p-4">
          <div className="flex flex-row justify-between">
            <span className="inter-normal text-sm line-height-22 custom-gray07">
              Location
            </span>
            <p className="inter-medium text-sm line-height-22 text-right custom-gray09">
              {/* Bibra Lake <br />
              (6163) */}
              {contactDetails.address ? contactDetails.address : "---"} <br />
              {contactDetails.postcode ? contactDetails.postcode : null}
            </p>
          </div>
          <div className="flex flex-row justify-between">
            <span className="inter-normal text-sm line-height-22 custom-gray07">
              Hours
            </span>
            <p className="inter-medium text-sm line-height-22 text-right custom-gray09">
              --
            </p>
          </div>
        </div>
        <div className="custom-blue01 rounded-b-lg p-4 flex flex-col gap-2">
          <p className="inter-normal text-sm line-height-22 custom-gray07">
            Booking Total
          </p>
          <h3 className="inter-medium line-height-22 text-[20px] custom-gray09">
            ${currentStep === 1 ? 0 : totalBookingPrice.totalPrice}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default BookingSummary;
