import React from "react";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
// import SearchIcon from "./../assets/search.png";
const HeroBannerContent = ({
  HeroContentHeading,
  deskContentPara,
  customfontSize,
  customleading,
  mobContentPara,
}) => {
  const navigate = useNavigate();
  const handleBookOnlineClick = () => {
    navigate("/booking-page");
  };
  return (
    <>
      <div className="flex p-4 flex-col gap-4 ">
        <h1
          className={`inter-medium text-[28px] leading-9  ${customfontSize}  ${customleading} custom-gray10 `}
        >
          {HeroContentHeading}
        </h1>
        <p className="sm:hidden block inter-normal text-start line-height-22 text-sm custom-gray08">
          {deskContentPara}
        </p>
        <p className="sm:block hidden inter-normal text-start line-height-22 text-sm custom-gray08">
          {mobContentPara}
        </p>
      </div>
      <div className="px-4">
        <Button
          onclick={handleBookOnlineClick}
          className="w-full py-2 rounded-md custom-blue-bg text-white custom-blue-border inter-medium desk-input-btn mobile-input-btn text-sm line-height-22"
          callDetails={"Book Online"}
        />
      </div>

      {/* <div className="px-4 flex gap-2.5 ">
        <div className="sm:w-full rounded-2xl opacity-100 custom-border bg-white  border  p-4 gap-4  md:w-[573px] flex md:flex-row flex-col md:justify-between justify-start md:items-center ">
          <div className="md:w-[307px] flex justify-start items-center gap-2 h-[22px] relative ">
            <img
              src={SearchIcon}
              alt="searchIcon"
              className="w-[20px] h-[20px] top-[1.88px] left-[1.88px] custom-input-text-color"
            />
            <input
              type="text"
              placeholder="Enter your post code"
              className="inter-normal block w-full focus:outline-none text-sm line-height-22 custom-gray06 bg-white "
            />
          </div>
          <Button
            callDetails={"Get Pricing & Booking Online"}
            className="sm:w-full py-2 md:w-[218px] rounded-md custom-blue-bg text-white custom-blue-border inter-medium desk-input-btn mobile-input-btn text-sm line-height-22"
          />
        </div>
      </div> */}
    </>
  );
};

export default HeroBannerContent;
