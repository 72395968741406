import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import Enquiry from "./Enquiry";
import Button from "../Button";
import Logo from "../../assets/mainlogo.png";
import mobileMenu from "../../assets/menu.png";
import closeMenu from "../../assets/close.png";
import { Link, NavLink, useNavigate } from "react-router-dom";

const navigationBar = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "Cleaning checklist",
    href: "/cleaning_checklist",
  },
  {
    name: "Contact Us",
    href: "/contact",
  },
  {
    name: "Terms & Condition",
    href: "/terms-and-condition",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Nav() {
  const navigate = useNavigate();

  const handleBookOnlineClick = () => {
    navigate("/booking-page");
  };

  return (
    <Disclosure as="nav">
      <div className="shadow-[0px_4px_16px_2px_rgba(17,29,15,0.047)] md:shadow-[0px_8px_32px_2px_rgba(17,29,20,0.047)] md:relative  md:h-[100px] md:p-0  md:items-center flex-col flex md:flex-row item-center">
        <div
          className="items-center sm:p-4 md:px-8 md:py-4 md:w-auto flex 
           md:items-center justify-between h-[89px]"
        >
          <Link to="/">
            <img src={Logo} alt="MyLogo" className="w-[126px] object-cover" />
          </Link>
          <div
            className="
           right-1 sm:flex sm:justify-center items-center md:hidden sm:w-10 sm:h-10"
          >
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md">
              <div className="rounded-md max-w-10 max-h-10 flex justify-center items-center">
                <img
                  src={mobileMenu}
                  alt="mobilemenu"
                  className="block h-5 w-5 group-data-[open]:hidden"
                />
                <img
                  src={closeMenu}
                  alt="closemenu"
                  className="hidden h-5 w-5 group-data-[open]:block "
                />
              </div>
            </DisclosureButton>
          </div>
        </div>

        <div className="hidden sm:ml-6 md:block md:grow md:px-8">
          <div className="md:flex md:space-x-4 items-center md:gap-3 h-12 justify-center">
            {navigationBar.map((item, id) => (
              <li className="list-none outline-none" key={id}>
                <NavLink
                  to={item.href}
                  className={({ isActive }) =>
                    classNames(
                      "inter-medium text-sm line-height-22",
                      isActive ? "custom-blue-text" : "custom-gray10"
                    )
                  }
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
          </div>
        </div>
        <div className="md:gap-4 md:px-8 right-0 flex items-center h-12  ">
          <Enquiry
            enquiries={"enquiries Monday - Friday"}
            timing={"8:30am - 5pm AEST"}
          />
          <Link
            to="callto: 0474769147"
            className="sm:w-1/2 md:min-w-[200px] h-12 flex justify-center items-center text-sm md:rounded-md rounded-none inter-medium text-center custom-gray10  border border-[#f0f0f0] md:border-[#BFBFBF]"
          >
            Call (0474769147)
          </Link>

          <Button
            onclick={handleBookOnlineClick}
            className="sm:w-1/2 md:min-w-[200px] h-12 text-sm custom-blue-bg font-semibold inter-medium text-white  text-center md:rounded-md rounded-none"
            callDetails={"Book Online"}
          />
        </div>
      </div>
      <DisclosurePanel className="md:hidden block">
        <div className="p-4 flex flex-col inter-medium gap-[10px]">
          {navigationBar.map((item, id) => (
            <li
              className="space-y-2 list-none hover:no-underline menu-text-decoration-none"
              key={id}
            >
              <NavLink
                to={item.href}
                className={({ isActive }) =>
                  classNames(
                    "hover:no-underline menu-text-decoration-none rounded-md px-4 h-12 inter-medium text-sm flex flex-col justify-center ",
                    isActive ? "custom-blue-text" : "custom-gray10"
                  )
                }
              >
                {item.name}
              </NavLink>
            </li>
          ))}
          <div className="flex flex-wrap gap-2">
            <Button
              className="w-full h-12 text-sm rounded-md  inter-medium text-center custom-gray10 border border-[#D9D9D9]"
              callDetails={"Call (0474769147)"}
            />
            <Button
              onclick={handleBookOnlineClick}
              className="w-full h-12 text-sm custom-blue-bg font-semibold inter-medium text-white text-center rounded-md"
              callDetails={"Book Online"}
            />
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
}
