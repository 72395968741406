import React from "react";
// import BookingNav from "../components/BookingComponents/BookingNav";
import BookingAllStepsComp from "../components/BookingComponents/BookingStepsComponent";
import { ToastContainer } from "react-toastify";
const BookingPage = () => {
  return (
    <>
      {/* <div className="md:max-w-[1440px] sm:w-full mx-auto flex justify-between items-center">
        <BookingNav />
      </div> */}
     
        <BookingAllStepsComp />
        <ToastContainer />
      
    </>
  );
};

export default BookingPage;
