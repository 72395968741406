import React from "react";
import ContactUs from "../components/ContactUsComp/ContactUs";

const ContactUsPage = () => {
  return (
    <div className="relative">
      <ContactUs />
    </div>
  );
};

export default ContactUsPage;
